<template lang="pug">
	.main-wrapper.faturamento-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub /
						<router-link :to='{ name: "faturamento" }'> Faturamento</router-link> /
						<b>Guias</b>

		form.form-finalizar(@submit.prevent='handleSubmit()')
			ProgressBar(v-if='waitingForm' mode="indeterminate")
			div(v-else)
				Panel(header="Faturar")
					.p-grid.p-fluid.p-align-end

						.p-col-12.p-md-4
							label.form-label Estabelecimento:
							InputText(v-model='model.nm_estabelecimento' :readonly='true')
						
						.p-col-6.p-md-4
							label.form-label Data início:
							InputText(v-model='model.dt_inicial_f' :readonly='true')
						
						.p-col-6.p-md-4
							label.form-label Data fim:
							InputText(v-model='model.dt_final_f' :readonly='true')

						.p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_competencia.$error }")
							label.form-label Data de competência:
							Calendar( v-model='$v.model.dt_competencia.$model' dateFormat="mm/yy" :locale="$parent.ptbr" :manualInput='false'
								yearRange="2020:2020" :yearNavigator='true' view='month' :readonly='true')
							.feedback--errors(v-if='submitted && $v.model.dt_competencia.$error')
								.form-message--error(v-if="!$v.model.dt_competencia.required") <b>Data de competência</b> é obrigatório.

						.p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_pagamento.$error }")
							label.form-label Data de pagamento:
							.p-inputgroup
								Calendar( v-model='$v.model.dt_pagamento.$model' dateFormat="dd/mm/yy" :locale="$parent.ptbr")
								Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='model.dt_pagamento = null')
							.feedback--errors(v-if='submitted && $v.model.dt_pagamento.$error')
								.form-message--error(v-if="!$v.model.dt_pagamento.required") <b>Data de pagamento</b> é obrigatório.

						.p-col-6.p-md-4
							label.form-label Nº nota fiscal:
							InputText(v-model='model.nr_nota_fical')

						.p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_banco.$error }")
							label.form-label Banco:
							Dropdown(v-model='$v.model.cd_banco.$model' :options='options.bancos' dataKey='id'
								optionLabel='nm_banco' optionValue='id' placeholder='Selecione' :filter='options.bancos.length > 0')
							.feedback--errors(v-if='submitted && $v.model.cd_banco.$error')
								.form-message--error(v-if="!$v.model.cd_banco.required") <b>Banco</b> é obrigatório.
								
						.p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_agenciabancaria.$error }")
							label.form-label Nº agência bancária:
							InputMask(type='tel' v-model='$v.model.nr_agenciabancaria.$model' mask='999999' slotChar='' :autoClear='false')
							.feedback--errors(v-if='submitted && $v.model.nr_agenciabancaria.$error')
								.form-message--error(v-if="!$v.model.nr_agenciabancaria.required") <b>Nº agência bancária</b> é obrigatório.
								
						.p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_contabancaria.$error }")
							label.form-label Nº conta:
							InputMask(type='tel' v-model='$v.model.nr_contabancaria.$model' mask='999999' slotChar='' :autoClear='false')
							.feedback--errors(v-if='submitted && $v.model.nr_contabancaria.$error')
								.form-message--error(v-if="!$v.model.nr_contabancaria.required") <b>Nº conta</b> é obrigatório.
								
						.p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_operacaobancaria.$error }")
							label.form-label Nº operação:
							InputMask(type='tel' v-model='$v.model.nr_operacaobancaria.$model' mask='999999' slotChar='' :autoClear='false')
							.feedback--errors(v-if='submitted && $v.model.nr_operacaobancaria.$error')
								.form-message--error(v-if="!$v.model.nr_operacaobancaria.required") <b>Nº operação</b> é obrigatório.
						
						.p-col-6.p-md-4
							label.form-label Valor bruto:
							InputText(v-model='model.nr_valorbruto_f' :readonly='true')

						.p-col-6.p-md-4
							label.form-label Desconto INSS:
							.p-inputgroup
								span.p-inputgroup-addon R$
								span(style='flex:1')
									currency-input.input-nr_descontoinss.p-inputnumber-input.p-inputtext.p-component.p-filled(
										v-model='model.nr_descontoinss'
										locale='pt-BR'
										:readonly='true'
										:auto-decimal-mode='true'
										:value-range='{ min: 0 }')

						.p-col-6.p-md-4
							label.form-label Imposto federal:
							.p-inputgroup
								span.p-inputgroup-addon R$
								span(style='flex:1')
									currency-input.input-nr_impostofederal.p-inputnumber-input.p-inputtext.p-component.p-filled(
										v-model='model.nr_impostofederal'
										locale='pt-BR'
										:readonly='true'
										:auto-decimal-mode='true'
										:value-range='{ min: 0 }')

						.p-col-6(v-if='! model.id')
							a(@click='$parent.finalizar = false' style='cursor:pointer') Visualizar guias

						.ta-right(:class="{ 'p-col-12': model.id, 'p-col-6': !model.id }")
							Button(:label=" model.id ? 'Salvar' : 'Confirmar Faturamento' " icon='jam jam-check' style='max-width:150px')

</template>

<style lang="scss">
	.faturamento-listar {
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 10px 0;
				text-align: center;
				font-weight: 700;
			}
			.p-highlight {
				.cell { background-color: #007ad9 !important; }
			}
		}

		.form-finalizar {
			.p-calendar > .p-inputtext {
				background-color: #fff;
				font-weight: 400;
			}

			.p-inputtext[readonly=readonly] {
				background-color: #eee;
				font-weight: 700;
			}
			.p-inputtext[readonly=readonly] {
				background-color: #eee;
				font-weight: 700;
			}
			
		}
	}
</style>

<script>
	import InputText from 'primevue/inputtext'
	import ProgressBar from 'primevue/progressbar'
	import Panel from 'primevue/panel'
	import Calendar from 'primevue/calendar'
	import Button from 'primevue/button'
	import Dropdown from 'primevue/dropdown'
	import InputMask from 'primevue/inputmask'
	import Tooltip from 'primevue/tooltip'
	import { required } from 'vuelidate/lib/validators'
	import moment from 'moment'
	import { Bancos, Pagamentos } from '../../middleware'
	export default {
		created () {
			Bancos.findAll().then(response => {
				if (response.status == 200) this.options.bancos = response.data

				if (! isNaN(this.$route.params.id)) this.id = parseInt(this.$route.params.id)
				if (this.id != 0) this.finalizar = true

				Pagamentos.find(this.id).then(response => {
					
					if (response.status === 200) {
						this.waitingForm = false

						this.model.id = response.data.id
						this.model.nm_estabelecimento = response.data.cd_estabelecimento.nm_fantasia
						this.model.cd_estabelecimento = response.data.cd_estabelecimento.id
						this.model.dt_inicial = response.data.dt_inicial
						this.model.dt_inicial_f = moment(response.data.dt_inicial).format('DD/MM/YYYY')
						this.model.dt_final = response.data.dt_final
						this.model.dt_final_f = moment(response.data.dt_final).format('DD/MM/YYYY')
						this.model.dt_competencia = moment(response.data.dt_competencia)._d
						this.model.dt_pagamento = moment(response.data.dt_pagamento)._d
						this.model.cd_banco = response.data.cd_banco.id
						this.model.nr_agenciabancaria = response.data.nr_agenciabancaria
						this.model.nr_contabancaria = response.data.nr_contabancaria
						this.model.nr_operacaobancaria = response.data.nr_operacaobancaria
						this.model.nr_valorbruto = parseFloat(response.data.nr_valorbruto)
						this.model.nr_descontoinss = parseFloat(this.model.nr_descontoinss)
						this.model.nr_impostofederal = parseFloat(this.model.nr_impostofederal)
						this.model.nr_valorbruto_f = this.formatPrice(response.data.nr_valorbruto)

					} else {
						this.$router.push({ name: 'faturamento' });
					}
				}) 
			})
			setTimeout(() => {
				let el = document.querySelector('.input-nr_descontoinss');
				if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
				{ this.model.nr_descontoinss = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
				el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }

				el = document.querySelector('.input-nr_impostofederal');
				if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
				{ this.model.nr_impostofederal = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
				el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }
			})
		},
		components: { InputText, ProgressBar, Panel, Calendar, Button, Dropdown, InputMask, Tooltip },
		directives: { tooltip: Tooltip },
		data () {
			return {
				model: {
					cd_estabelecimento: null,
					dt_inicial: null,
					dt_final: null,
					dt_competencia: null,
					dt_pagamento: null,
					cd_banco: null,
					nr_agenciabancaria: null,
					nr_contabancaria: null,
					nr_operacaobancaria: null,
					nr_valorbruto: null,
					cd_guia_eletronica: [],
					nr_descontoinss: 0,
					nr_impostofederal: 0,
					ie_status_pagamento: 'A'
				},
				options: {
					bancos: []
				},
				waitingForm: true,
				submitted: false
			}
		},
		validations () {
			let v = {
				model: {
					dt_competencia: { required },
					dt_pagamento: { required },
					cd_banco: { required },
					nr_agenciabancaria: { required },
					nr_contabancaria: { required },
					nr_operacaobancaria: { required },
					nr_valorbruto: { required },
					nr_descontoinss: { required },
					nr_impostofederal: { required }
				}
			}
			
			return v
		},
		methods: {
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			handleSubmit () {
				this.submitted = true
				
				this.$v.$touch()
				if (this.$v.$invalid) return 0

				let dataSend = Object.assign({})
				dataSend.id = this.model.id
				dataSend.dt_pagamento = moment(this.model).format('YYYY-MM-DD')
				dataSend.cd_banco = this.model.cd_banco
				dataSend.nr_agenciabancaria = this.model.nr_agenciabancaria
				dataSend.nr_operacaobancaria = this.model.nr_operacaobancaria
				dataSend.nr_nota_fical = this.model.nr_nota_fical
				dataSend.ie_status_pagamento = 'P'

				this.waitingForm = true
				Pagamentos.save(dataSend).then(response => {
					if (([200, 201]).includes(response.status)) {
						if (! this.model.id) this.$router.push({ name: 'faturamento' })
						this.$toast.success('Pagamento registrado com sucesso', { duration: 3000 })
					} else if (([400]).includes(response.status)) {
						if (response.data)
							Object.values(response.data).forEach(error => {
								error.forEach(errorMsg => this.$toast.error(errorMsg, { duration: 3000 }))
							})
					}
					this.waitingForm = false
				})
			}
		}
	}
</script>